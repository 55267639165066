.carousel .carousel-item {
    position: relative;
    height: 60vh; /* Adjust this value to change the height */
  }
  
  .carousel-item img {
    position: absolute;
    object-fit: cover; /* This will ensure your images cover the entire area of the carousel item without stretching */
    top: 0;
    left: 0;
    width: 100%;
    height:100%; /* This should match the height specified above */
  }
  
  .row {
    justify-content: center;
  }

  .carousel-container p {
    border-radius: 10px;
    width: fit-content;
    display: inline-flex;
  }

  .my-button {
    width:100%;
  }

  .my-button:hover {
    background-color: #e4a11b;
  }