@import url('https://fonts.googleapis.com/css2?family=Oleo+Script&display=swap');

.goldtext {
    color:#dfcc89;
    font-family: 'Oleo Script', cursive;
    font-size:x-large;
}

.bronzenavtext {
    color: #dfcc89;
    font-family: "Mulish", sans-serif;
}

.oleoScript {
    font-family: 'Oleo Script', cursive;
}

.goldBackground {
    background-color: #dfcc89;
}

.active {
    color: #e4a11b !important;
}